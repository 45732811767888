@import '../../assets/styles/Colors.scss';

.cb-overall-data-section,
.cb-overall-data-section .cb-quotes-stats-wrapper {
    display: flex;
    flex-wrap: wrap;
}

.cb-overall-data-section {
    justify-content: space-between;
    padding: 1em 0 0 2em;
    margin-top: 2em;
}

@media only screen and (max-width: 767px) {
    .cb-overall-data-section {
        padding: 1em 0 0 0;
    }
}

.cb-overall-data-section .cb-quotes-stats-wrapper {
    justify-content: space-evenly;
    align-items: flex-end;
    flex-grow: 1;
    padding-top: 4em;
}

.cb-overview-tables-section,
.cb-shortcuts-section {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 2em;
}

.cb-closed-quotes-amounts-breakdown-modal {
    width: 100%!important;
}

.cb-closed-quotes-amounts-breakdown-modal .content {
    display: flex!important;
    justify-content: center;
    flex-wrap: wrap;
}

.cb-closed-quotes-amounts-breakdown-modal .content .cb-quotes-amount-stat {
    background-color: white;
    max-width: 300px;
    margin: 1em;
}