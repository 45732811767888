@import '../../../assets/styles/Colors.scss';

.cb-quote-request-confirmation-segment .cb-pdf-viewer {
    width: 100%;
    height: 57vh;
    margin-bottom: 1em;
}

.cb-quote-request-confirmation-actions {
    padding-top: 0.5em;
    padding-bottom: 0.8em;
    text-align: right;
}

.cb-quote-request-confirmation-actions .ui.button:not(:last-child) {
    margin: 0 0.5em 0 0!important;
}

.cb-quote-request-confirmation-actions .ui.button:last-child {
    margin: 0!important;
}

.cb-back-to-form {
    background-color: rgba(181, 181, 181, 0.6)!important;
    color: #111!important;
    box-shadow: 0 0 0 1px #AAA inset!important;
}

.cb-back-to-form:hover {
    background-color: rgba(181, 181, 181, 0.4)!important;
}

.cb-send-quote-request {
    box-shadow: 0 0 0 1px #27292A inset!important;
    color: #27292A!important;
}

.cb-send-quote-request:hover {
    background: transparent-white(0.5)!important;
}