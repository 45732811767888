@import '../../../assets/styles/Colors.scss';

.cb-overview-card {
    user-select: none;
    min-width: 170px;
}

.cb-overview-card:hover {
    background-color: $bright-green!important;
}

.cb-overview-card .cb-overview-title {
    color: rgba(0, 0, 0, 0.68)!important;
}

.cb-overview-card:hover .cb-overview-title,
.cb-overview-card:hover .cb-overview-content {
    color: $light-blue!important;
}