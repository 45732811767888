@import '../../../assets/styles/Colors.scss';

.cb-no-pending-requests {
    font-size: 2rem;
    text-align: center;
}

.cb-no-pending-requests > .header {
    margin-top: 0!important;
}

.cb-no-pending-requests > .header > .sub.header {
    font-size: 2rem!important;
}

.cb-access-requests-table {
    font-size: 1.2em!important;
}

.cb-access-requests-table thead tr th {
    color: $dark-green!important;
}

.cb-access-requests-table tbody tr td {
    color: #2C5282!important;
    padding-top: 1.5vw!important;
    padding-bottom: 1.5vw!important;
}

.cb-access-requests-table tbody tr td .cb-accept-access-request-button,
.cb-access-requests-table tbody tr td .cb-reject-access-request-button {
    margin-bottom: 0!important;
    box-shadow: none!important;
    border-radius: 2em!important;
    width: 35%!important;
}

.cb-access-requests-table tbody tr td .cb-access-request-action-button.cb-accept-access-request-button,
.cb-access-requests-table tbody tr td .cb-access-request-action-button.cb-accept-access-request-button:active,
.cb-access-requests-table tbody tr td .cb-access-request-action-button.cb-accept-access-request-button:visited,
.cb-access-requests-table tbody tr td .cb-access-request-action-button.cb-accept-access-request-button:focus {
    background-color: transparent!important;
    color: #6CB856!important;
    border: 1px solid #6CB856!important;
    margin-right: 0.5em!important;
    min-width: 100px!important;
}

.cb-access-requests-table tbody tr td .cb-access-request-action-button.cb-accept-access-request-button:hover {
    color: white!important;
    background-color: $dark-green!important;
    border-color: $dark-green!important;
}

.cb-access-requests-table tbody tr td .cb-access-request-action-button.cb-reject-access-request-button,
.cb-access-requests-table tbody tr td .cb-access-request-action-button.cb-reject-access-request-button:active,
.cb-access-requests-table tbody tr td .cb-access-request-action-button.cb-reject-access-request-button:visited,
.cb-access-requests-table tbody tr td .cb-access-request-action-button.cb-reject-access-request-button:focus {
    background-color: transparent!important;
    color: #B02747!important;
    border: 1px solid #B02747!important;
    min-width: 115px!important;
}

.cb-access-requests-table tbody tr td .cb-access-request-action-button.cb-reject-access-request-button:hover {
    color: white!important;
    background-color: #B02747!important;
    border-color: #B02747!important;
}
