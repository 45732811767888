@import '../../../../assets/styles/Colors.scss';


.cb-form-section-header .cb-info-tooltip-icon {
    font-size: 1.09em !important;
    margin-left: 0.5em!important;
}

.cb-form-section .cb-form-section-header {
    display: flex;
    align-items: center;
}

.cb-form-section.cb-checkable-section .cb-form-section-header .field {
    margin-right: 0.5em;
}

.cb-form-section.cb-level-1 > .cb-form-section-header {
    padding: 0.3em 0 0.3em 0.5em!important;
    background-color: #CBCBCB!important;
    border: 1px solid rgba(34, 36, 38, 0.15)!important;
}

.cb-form-section.cb-level-2 > .cb-form-section-header {
    padding: 0.2em 0 0.2em 0.4em!important;
    background-color: #F1F1F1!important;
    border: none!important;
    border-bottom: 1px solid rgba(34, 36, 38, 0.15)!important;
    font-size: 1.22em!important;
}

.cb-form-section.cb-level-3 > .cb-form-section-header {
    padding: 0.1em 0 0.1em 0.2em!important;
    background-color: #F6F6F6!important;
    border-bottom: 1px solid rgba(34, 36, 38, 0.05)!important;
    font-size: 1.04em!important;
}

.cb-form-section.cb-level-1:not(.cb-marginless-top) {
    margin-top: 3.9em!important;
}

.cb-form-section.cb-level-2:not(.cb-marginless-top),
.cb-form-section.cb-level-3:not(.cb-marginless-top) {
    margin-top: 3.2em!important;
}