@import '../../../assets/styles/Colors.scss';

.cb-quote-communication-wrapper {
    padding: 1em 1em 0.7em 1em;
}

.cb-quote-communication .cb-quote-messages {
    overflow-y: auto;
    box-shadow: 0px 0px 10px #FFF;
    border-radius: 3px;
}

.cb-quote-communication .cb-message-compose {
    margin-top: 1em;
}

.cb-quote-communication .cb-quote-messages .cb-message {
    display: flex;
    padding: 1em 0;
    color: #111;
}

.cb-quote-communication .cb-quote-messages .cb-message:not(.cb-own-message) {
    background: $light-green;
}

.cb-quote-communication .cb-quote-messages .cb-message:not(:last-child) {
    border-bottom: 1px dotted grey;
}

.cb-quote-communication .cb-quote-messages .cb-message.cb-own-message {
    background: transparentize($bright-green, 0.6);
    color: white;
}

.cb-quote-communication .cb-quote-messages .cb-message .cb-date {
    padding: 0 2em;
    font-size: smaller;
    font-style: italic;
    border-right: 1px dashed #999;
    flex-shrink: 0;
}

.cb-quote-communication .cb-quote-messages .cb-message .cb-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 1em;
    word-break: break-word;
}

.cb-quote-communication .cb-quote-messages .cb-message .cb-content .cb-sender {
    border-bottom: 1px solid #999;
    font-weight: bold;
    margin-bottom: 0.5em;
}

.cb-quote-communication .cb-quote-messages .cb-message .cb-content .cb-text {
    white-space: pre-wrap;
}

.cb-quote-communication .cb-empty-message {
    color: lightgrey;
    font-size: 1.8em;
    font-style: italic;
    font-weight: bold;
    text-align: center;
    padding: 1em 0;
}

.cb-quote-communication-wrapper .cb-quote-generic-actions {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-between;
    margin-top: 1em;
}

.cb-quote-communication-wrapper .cb-quote-generic-actions .ui.button {
    border-radius: 0!important;
    box-shadow: 0 0 0 1px #27292A inset!important;
    color: #27292A!important;
}

.cb-quote-communication-wrapper .cb-quote-generic-actions .ui.button:last-child {
    margin-right: 0!important;
}

.cb-quote-communication-wrapper .cb-quote-generic-actions .ui.button:not(:last-child) {
    margin-right: 2px!important;
}

.cb-quote-communication-wrapper > input[type=file] {
    display: none;
}