@import '../../../assets/styles/Colors.scss';

.cb-user-info-widget {
    padding: 0.5em 1em 1em 0;
    user-select: none;
    z-index: 1;
}

.cb-user-info-widget .cb-main-content {
    padding: 1em 1em;
    display: flex;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 5px 5px 0 0;
    background-color: transparent-white(0.9);
}

.cb-user-info-widget:hover .cb-main-content {
    background-color: #EBEBEB;
    border: 1px solid #DFDFDF;
}

.cb-user-info-widget .cb-user-data {
    margin-right: 1.5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

@media only screen and (max-width: 767px) {
    .cb-user-info-widget .cb-user-data {
        display: none;
    }

    .cb-user-info-widget:hover .cb-user-data {
        display: flex;
    }
}

.cb-user-info-widget .cb-user-name {
    font-size: 1.3em;
    color: $dark-green;
}

.cb-user-info-widget .cb-user-brokerage {
    font-size: 1.1em;
    color: $dark-blue;
    margin-top: 0.2em;
}

.cb-user-info-widget .cb-main-content > img {
    width: 45px;
}

.cb-user-info-widget .cb-user-options {
    border-radius: 0 0 5px 5px;
    border-color: #DFDFDF;
    border-style: solid;
    border-width: 0 1px 1px 1px;
    background-color: white;
    box-shadow: 0px 5px 10px -6px #444;
}

.cb-user-info-widget .cb-user-option {
    cursor: pointer;
    padding: 1em 1.5em;
    font-size: 1.03em;
}

.cb-user-info-widget .cb-user-option:hover {
    background-color: #F3F3F3;
    font-weight: bold;
}

.cb-user-info-widget .cb-user-option:first-child {
    border-bottom: 1px solid #DFDFDF;
}