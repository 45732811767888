@import '../../../assets/styles/Colors.scss';

.cb-assigned-quotes-table-wrapper {
    max-width: 100%;
    border-radius: 4px;
    background-color: $light-green;
    border: 1px solid rgba(34, 36, 38, 0.15);
    margin-top: 3em;
}

.cb-assigned-quotes-table-header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: 1px solid rgba(34, 36, 38, 0.15);
}

.cb-assigned-quotes-search-bar,
.cb-closed-quotes-shortcut-menu {
    display: flex;
    flex: 1 1 0;
    padding-top: 1.3em;
    padding-bottom: 1.3em;
}

.cb-assigned-quotes-search-bar {
    padding-right: 1em;
    padding-left: 3.75em;
    max-width: 50%;
}

.cb-closed-quotes-shortcut-menu {
    justify-content: flex-end;
    padding-left: 1em;
    padding-right: 3.75em;
}

.cb-assigned-quotes-search-bar .cb-search-input {
    display: flex;
    flex-grow: 1;
}

.cb-assigned-quotes-search-bar .cb-search-input input {
    border: 2px solid #2A4365!important;
}

.cb-assigned-quotes-search-bar .ui.button.cb-sorting-button {
    margin-left: 1em;
    margin-right: 0;
}

.cb-closed-quotes-shortcut-menu .cb-amount-label {
    background-color: #2B5724!important;
}

@media only screen and (max-width: 767px) {
    .cb-assigned-quotes-search-bar,
    .cb-closed-quotes-shortcut-menu {
        max-width: 100%;
        width: 100%;
        padding-left: 1.5em;
        padding-right: 1.5em;
    }

    .cb-closed-quotes-shortcut-menu .ui.menu {
        border-bottom: none!important;
    }
}

.cb-assigned-quotes-table {
    max-height: 60vh;
    overflow-y: auto;
}

.cb-assigned-quotes-table > table,
.cb-assigned-quotes-table > table tr,
.cb-assigned-quotes-table > table th,
.cb-assigned-quotes-table > table td,
.cb-assigned-quotes-page-management > table,
.cb-assigned-quotes-page-management > table tr,
.cb-assigned-quotes-page-management > table th,
.cb-assigned-quotes-page-management > table td, {
    background: transparent!important;
    margin: 0!important;
    border: none!important;
}

.cb-assigned-quotes-table > table th,
.cb-assigned-quotes-table > table td {
    font-size: 1.1em;
}

.cb-assigned-quotes-table > table tr th,
.cb-assigned-quotes-table > table tr:not(:last-child) td {
    border-bottom: 1px solid #D4D4D4!important;
}

.cb-assigned-quotes-table > table tr:nth-child(2n+1) td {
    background: white!important;
}

.cb-assigned-quotes-table > table th {
    color: $dark-blue!important;
}

.cb-assigned-quotes-table > table th:first-child,
.cb-assigned-quotes-table > table td:first-child,
.cb-assigned-quotes-page-management > table th:first-child,
.cb-assigned-quotes-page-management > table td:first-child {
    padding-left: 3rem!important;
}

.cb-assigned-quotes-table > table th:last-child,
.cb-assigned-quotes-table > table td:last-child,
.cb-assigned-quotes-page-management > table th:last-child,
.cb-assigned-quotes-page-management > table td:last-child {
    padding-right: 3rem!important;
}

.cb-assigned-quotes-table > table td {
    color: #2E3B52!important;
}

.cb-assigned-quotes-table > table tr.cb-quote:hover {
    cursor: pointer;
}

.cb-assigned-quotes-table > table tr:hover td {
    background: $bright-green!important;
    color: white!important;
}

.cb-assigned-quotes-table > table tr td .cb-modality-label {
    background-color: #2B5724;
    color: $light-blue;
    border-radius: 15px;
    width: 100%;
    text-align: center;
}

.cb-assigned-quotes-table > table tr:hover td .cb-modality-label {
    background-color: $light-blue;
    color: #2B5724;
}

.cb-user-management-modal .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cb-user-management-modal .cb-deregister-user {
    color: darkred;
    user-select: none;
    padding: 0.4em 1em;
    border: 1px solid transparent;
}

.cb-user-management-modal .cb-deregister-user:hover {
    background-color: #EEE;
    border: 1px solid lightgrey;
    border-radius: 5px;
    cursor: pointer;
}

.cb-user-management-modal .cb-deregister-user .icon {
    margin-right: 0.5em;
}

.cb-assigned-quotes-page-management {
    padding-top: 2em;
    border-top: 1px solid rgba(34, 36, 38, 0.15);
}

.cb-assigned-quotes-page-management .cb-page-size-dropdown {
    margin-left: 1.5em;
}

.cb-assigned-quotes-page-management .cb-page-size-dropdown .dropdown.icon {
    margin-left: 0.5em!important;
}

.cb-assigned-quotes-page-management .cb-page-results-numbers {
    word-spacing: 3px;
}

.cb-assigned-quotes-page-management .cb-page-switch-buttons {
    text-align: right!important;
}

.cb-assigned-quotes-page-management .cb-page-switch-buttons .ui.button,
.cb-assigned-quotes-search-bar .ui.button.cb-sorting-button {
    color: #2A4365!important;
    background-color: $off-white!important;
    border: 1px solid #2A4365!important;
}

.cb-assigned-quotes-page-management .cb-page-switch-buttons .ui.button:first-child {
    margin-right: 0.4em;
}

.cb-assigned-quotes-table-wrapper .cb-empty-message {
    width: 60%!important;
    margin: 2em auto!important;
}

.cb-sorting-modal form .field:last-child {
    margin-bottom: 1em!important;
}