@import '../../../assets/styles/Colors.scss';

.cb-centerrisk-logo {
    margin: auto;
}

.ui.segment.cb-access-request-segment {
    box-shadow: 0px 0px 10px #111;
    background: transparent-white(0.85);
    padding-left: 2em;
    padding-right: 2em;
    padding-bottom: 0.5em;
    min-width: 450px;
    max-width: 450px;
}

.ui.segment.cb-access-request-segment .cb-bottom-margin {
    margin: 0 0 1.5rem 0;
}