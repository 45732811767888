@import '../../../assets/styles/Colors.scss';

.cb-quote-form-wrapper {
    padding: 1em 1em 4em 1em;
    max-height: 73vh;
    overflow-y: auto;
}

.cb-quote-form-wrapper .cb-quote-generic-actions {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 2px;
    background: transparentize($dark-grey, 0.3);
    z-index: 1;
}

.cb-quote-form-wrapper .cb-quote-generic-actions .ui.button {
    box-shadow: 0 0 0 1px #27292A inset!important;
    border-radius: 0!important;
}

.cb-quote-form-wrapper .cb-quote-generic-actions .ui.button:not(.cb-highlighted) {
    color: #27292A!important;
}

.cb-quote-form-wrapper .cb-quote-generic-actions .cb-highlighted {
    background-color: $bright-green!important;
    color: white!important;
}

.cb-quote-form-wrapper .cb-quote-generic-actions .cb-highlighted:hover {
    background-color: white!important;
    color: $dark-green!important;
}

.cb-quote-form-wrapper .cb-quote-generic-actions .ui.button:last-child {
    margin-right: 0!important;
}

.cb-quote-form-wrapper .cb-quote-generic-actions .ui.button:not(:last-child) {
    margin-right: 2px!important;
}