@import '../../../assets/styles/Colors.scss';

.cb-quote-actions-wrapper {
    padding: 3em 1em;
    max-height: 73vh;
    overflow-y: auto;
}

.cb-quote-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: 0 20%;
}

.cb-quote-actions .ui.button {
    box-shadow: 0 0 0 1px #27292A inset!important;
    color: $dark-green!important;
    width: 100%!important;
    background-color: $light-green!important;
}

.cb-quote-actions .ui.button:hover {
    background-color: $bright-green!important;
    color: white!important;
}

.cb-quote-actions .ui.button:not(:last-child) {
    margin-bottom: 0.4em!important;
}

.cb-quote-actions .cb-empty-message {
    margin: 1em auto 0.5em!important;
}

@media only screen and (max-width: 767px) {
    .cb-quote-actions {
        padding: 0;
    }
}