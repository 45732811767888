@import '../../../assets/styles/Colors.scss';

.cb-centerrisk-logo {
    margin: auto;
}

.ui.segment.cb-login-segment {
    box-shadow: 0px 0px 10px #111;
    background: transparent-white(0.85);
    padding-left: 2em;
    padding-right: 2em;
    padding-bottom: 0.5em;
    min-width: 450px;
    max-width: 450px;
}

.ui.segment.cb-login-segment .cb-buttons {
    width: 100%;
    margin: 1.5rem 0;
}

.cb-buttons .button {
    width: 50%;
}

.cb-forgotten-password-text {
    color: $dark-grey;
    cursor: pointer;
    font-size: smaller;
}

.cb-forgotten-password-text:hover {
    color: $dark-grey;
    text-decoration: underline;
}

.cb-copyright {
    text-align: center;
    color: grey;
    font-size: small;
}