@import '../../assets/styles/Colors.scss';

.cb-brokerage-wrapper {
    margin: 20px;
    padding: 20px;
    border-radius: 4px;
    background-color: $light-green;
    border: 1px solid rgba(34, 36, 38, 0.15);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 3em;
}