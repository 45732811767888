@import '../../../../../assets/styles/Colors.scss';


.cb-dynamic-data-table + .ui.attached.cb-add-row-button {
    margin-bottom: 2em!important;
    background-color: #EEE!important;
    box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.45)!important;
}

.cb-dynamic-data-table + .ui.attached.cb-add-row-button:hover {
    background-color: #EAEAEA!important;
}

.cb-dynamic-data-table .deleteCol {
    width: 5%;
}
