@import '../../assets/styles/Colors.scss';

.cb-quote-form .cb-form-header {
    margin-top: 0!important;
    padding: 0.3em 0 0.3em 0.5em!important;
    background-color: #FBFBFB;
    border: 1px solid rgba(34, 36, 38, 0.15)!important;
}


.cb-quote-form .cb-top-margin-big {
    margin-top: 3em!important;
}

.cb-quote-form .cb-top-margin-medium {
    margin-top: 2em!important;
}

.cb-quote-form .cb-top-margin-small {
    margin-top: 1.4em!important;
}

.cb-quote-form .cb-half-width {
    width: 50%!important;
}

.guarantor-field {
    display: none;

    &.visible {
        display: initial;
    }
}

@media only screen and (max-width: 767px) {
    .cb-quote-form .cb-half-width {
        width: 100%!important;
    }
}