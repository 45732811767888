// Main used colors
$dark-green: #1D3905;
$bright-green: #79BB3E;
$light-green: #DAECE0;
$excel-green: #2E7D32;
$dark-grey: #5E5E5E;
$off-white: #F7F8FC;
$dark-blue: #606F89;
$light-blue: #DDE2FF;
$error-red: #9F3A38;

// Detail colors
$mid-dark-grey: #BBB;
$light-grey: #C3C3C3;

// Status colors
$status-new: #BAC3F5;
$status-in-progress: #E3BE5F;
$status-closed: #D17560;

// Transparencies
@function transparent-white($opacity) {
    @return rgba(255, 255, 255, $opacity);
}

@function transparent-black($opacity) {
    @return rgba(0, 0, 0, $opacity);
}