@import '../../../assets/styles/Colors.scss';

.cb-quotes-amount-stat {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1em 0 2em;
    width: 25%;
    align-self: stretch;
    flex-wrap: wrap;
    min-width: 250px;
    border: 1px solid #DDD;
    border-radius: 2em;
    margin-bottom: 1em;
    background-color: transparent-white(0.5);
    user-select: none;
}

.cb-quotes-amount-stat:hover {
    cursor: pointer;
    box-shadow: 0px 0px 5px #999;
}

.cb-quotes-amount-stat .cb-stat-title {
    font-size: 1.4em;
    color: #9D9D9D;
    margin: 0 0 0.6em 0;
}

.cb-quotes-amount-stat:hover .cb-stat-title {
    color: #A6D997;
}

.cb-quotes-amount-stat .cb-stat-amount {
    font-size: 8vh;
    color: #2A4321;
    margin: 0;
    line-height: 1;
    word-break: break-all;
    text-align: center;
}

.cb-quotes-amount-stat:hover .cb-stat-amount {
    color: #415E37;
}

.cb-quotes-amount-stat.cb-new .cb-stat-amount {
    border-bottom: 7px solid $status-new;
}

.cb-quotes-amount-stat.cb-in-progress .cb-stat-amount {
    border-bottom: 7px solid $status-in-progress;
}

.cb-quotes-amount-stat.cb-closed .cb-stat-amount {
    border-bottom: 7px solid $status-closed;
}

.cb-quotes-amount-stat + .cb-status-hint {
    display: block;
    position: fixed;
    overflow: hidden;
    background-color: rgba(218, 236, 224, 0.9);
    padding: 0.5em 1em;
    color: #333;
    border: 1px solid green;
    border-radius: 5px;
    font-size: small;
    font-style: italic;
    width: 17%;
    min-width: 250px;
    z-index: 3;
    pointer-events: none;
}