@import './assets/styles/Colors.scss';

#root > main {
    width: 100%;
    height: 100%;
    display: flex;
    overflow-y: hidden;
}

textarea {
    min-height: 50px;
}

.ui.modal .header .icon {
    margin-right: 0.7em;
}

.ui.modal .actions .ui.button {
    margin-left: 0.3em;
}

.ui.form .field > label {
    color: $dark-green!important;
}

.ui.form .field.error > label {
    color: $error-red!important;
}

.cb-main-content-wrapper {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    position: relative;
    padding: 3em 5em;
}

.cb-main-content-wrapper .cb-top-widgets {
    position: absolute;
    display: flex;
    align-items: self-start;
    top: 0;
    right: 2em;
}

@media only screen and (max-width: 767px) {
    .cb-main-content-wrapper {
        padding: 3em 2em;
    }

    .cb-main-content-wrapper .cb-top-widgets {
        right: 0;
    }
}

.cb-flex {
    display: flex!important;
}

.cb-dark-green {
    color: $dark-green!important;
}

.cb-excel-green {
    color: $excel-green!important;
}

.cb-page-title {
    font-size: 1.5em!important;
    color: $dark-green!important;
    user-select: none;
}

.cb-page-title > span {
    background-color: #EEE;
    padding: 0.15em 0.3em;
    font-style: italic;
    border-radius: 5px;
    color: #555;
    margin: 0 5px;
}

.cb-page-title .sub.header {
    margin-top: 0.5em!important;
}

.cb-hidden {
    display: none!important;
}

.cb-marginless-top {
    margin-top: 0!important;
}

.cb-marginless-bottom {
    margin-bottom: 0!important;
}