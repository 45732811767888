@import '../../../assets/styles/Colors.scss';

.cb-primary-button {
    background-color: $dark-green!important;
    color: white!important;
    border: 1px solid $dark-green!important;
}

.cb-primary-button:hover {
    background-color: transparent-white(0.7)!important;
    color: $dark-green!important;
}