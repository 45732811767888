@import '../../../assets/styles/Colors.scss';

.cb-quote-companies-wrapper {
    padding: 3em 1em;

    .row {
        display: flex;
        align-items: flex-end;
        width: 100%;

        .col {
            padding: 0 5px;

            &.select {
                flex: 1 1 auto;
            }
        }
    }
}

.cb-companies-list {
    padding: 1em 1em;

    .company {
        padding: 10px;
        border: solid 1px #ddd;
        border-radius: 3px;
        background-color: #f5f5f5;
        margin: 10px 0;

        .header {
            width: 100%;
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            padding: 10px;
            background-color: #e9e9e9;
            border-radius: 4px;

            .company-name {
                font-size: 1.4em;
                font-weight: 600;
                flex: 1 1 auto;
            }

            .delete {
                cursor: pointer
            }
        }


        .inputs {
            display: block;
            width: 100%;

            .row {
                display: flex;
                align-items: center;
                margin-bottom: 20px;

                .field {
                    margin: 0 20px 0 0;

                    label {
                        line-height: 1;

                        input[type=checkbox] {
                            margin-right: 10px;
                        }
                    }
                }

            }

            textarea {
                width: 100%;
            }
        }

        .actions {
            width: 100%;
            text-align: right;
            margin-top: 20px;

        }
    }
}

.companies-datatable {
    width: 100%;
    background-color: #f5f5f5;

    .hidden {
        display: none;
    }

    thead {
        background-color: #e9e9e9;
        border-collapse: collapse;
        border: none;

        tr {
            th {
                text-align: left;
                padding: 20px;
            }

            &.nopad {
                th {
                    padding-top: 0;
                }
            }
        }

    }

    tbody {

        tr.hidden {
            display: none;
        }

        td {
            padding: 10px;
            color: #1D3905;
            vertical-align: top;
            border-bottom: solid 1px #ddd;

            &.company-name {
                font-weight: 600;
                font-size: 1rem;
            }

            .actions {
                display: flex;
                align-items: center;


                .delete-icon {
                    font-size: 1.2rem;
                    margin: 0 20px;
                    cursor: pointer;
                    color: #000000;
                }
            }

            .required,
            .input {
                width: 100%;
            }

            input {
                width: 100%;
                min-width: 85px;
            }

            textarea {
                display: block;
                width: 100%;
            }

            .status-control {
                margin-bottom: 10px;
            }

            .hidden {
                display: none;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .cb-quote-actions {
        padding: 0;
    }
}