@import '../../../assets/styles/Colors.scss';

.cb-sidebar {
    min-width: 230px;
    max-width: 230px;
    background-color: $dark-green;
    box-shadow: 0px 0px 7px #111;
    user-select: none;
    overflow-y: auto;
    padding-bottom: 2em;
    display: flex;
    flex-direction: column;
    height: 100vh
}

.cb-sidebar > header {
    display: flex;
    align-items: top;
    padding: 2.5em 2.2em;
    cursor: default;
    position: sticky;
    top: 0;
}

.cb-sidebar > header > img {
    height: 40px;
}

.cb-sidebar > header > h3 {
    margin: 0 0 0 0.5em;
    color: $light-grey;
}

.sidebar-link {
    display: flex; /* Esto activa flexbox para el contenedor */
    align-items: center; /* Esto alinea verticalmente el icono y el texto en el centro */
    justify-content: flex-start; /* Esto alinea horizontalmente el icono y el texto al inicio */
  }
  
.sidebar-link-icon {
    margin-right: 8px;
}
  
.sidebar-content {
    overflow-y: auto;
}

.powered-by {
    margin-top: auto;
}

@media only screen and (max-width: 767px) {
    .cb-sidebar {
        display: none;
    }
}