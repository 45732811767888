@import '../../../../../assets/styles/Colors.scss';

.cb-file-field {
    flex-direction: column;
    align-items: flex-start!important;
}

.cb-files-requirements {
    width: 100%;
    margin-top: 0.5em!important;
    padding-top: 0.5em!important;
}

.cb-files-requirements > .ui.ribbon.label {
    box-shadow: 0px 0px 2px #111;
}

.cb-files-requirements .cb-file-requirement {
    padding-left: 1em!important;
}

.cb-files-requirements .cb-file-requirement.cb-with-label > .icon {
    padding-top: 0.3em!important;
}

.cb-files-requirements .cb-file-requirement .cb-file-item {
    user-select: none;
    margin: 0 0 0 1em!important;
    box-shadow: 0px 0px 2px #333;
}

.cb-files-requirements .cb-file-requirement .cb-file-item:hover {
    background-color: transparent-black(0.1);
    box-shadow: 0px 0px 2px #222;
}

.cb-files-requirements .cb-file-requirement .cb-file-item .download.icon {
    margin: 0!important;
}

.cb-file-upload-container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 0.5em;
}

.cb-file-upload-container > label {
    display: flex;
    background-color: #FCFFFD;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px dashed grey;
    padding: 1.5em 1em;
    color: grey;
    user-select: none;
}

.error .cb-file-upload-container > label {
    background-color: #FCEDEE;
    border: 1px dashed $error-red;
}

.cb-file-upload-container > label * {
    pointer-events: none;
}

.cb-file-upload-container > label.cb-files-selected {
    width: 50%;
}

.cb-file-upload-container > label.cb-dragged-over,
.cb-file-upload-container > label:hover {
    background-color: #EEE;
    color: #676767;
}

.error .cb-file-upload-container > label.cb-dragged-over,
.error .cb-file-upload-container > label:hover {
    background-color: #FFE6E7;
}

.cb-file-upload-container > label:hover {
    cursor: pointer;
}

.cb-file-upload-container > label > div {
    display: flex;
    align-items: center;
    margin-bottom: 0.6em;
}

.cb-file-upload-container > label > div span {
    font-size: 2em;
    text-align: center;
}

.cb-file-upload-container > label > div .icon {
    margin-left: 1em;
    font-size: 1.4em;
}

.cb-file-upload-container > label > span {
    text-align: center;
}

.cb-file-upload-container input[type=file] {
    display: none;
}

.cb-file-upload-container .cb-files-list {
    padding: 0.8em 1em;
    box-shadow: inset 0px 11px 8px -10px #CCC, inset 0px -11px 8px -10px #CCC;
    border-right: 1px solid #E6E6E6;
    max-height: 150px;
    overflow-y: auto;
    width: 50%;
}

.cb-file-upload-container .cb-files-list .header:not(:first-child) {
    margin-top: 1em!important;
}

.cb-file-upload-container .cb-files-list .cb-file-item,
.cb-files-requirements .cb-file-requirement .cb-file-item {
    background-color: $light-green;
}

i.close {
    cursor: pointer;
}

@media only screen and (max-width: 767px) {
    .cb-file-upload-container > label,
    .cb-file-upload-container > label.cb-files-selected,
    .cb-file-upload-container .cb-files-list {
        width: 100%;
    }
}