@import '../../../assets/styles/Colors.scss';


.cb-quote-statistics-panel {
    margin-top: 3em;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 4px;
    background: $light-green;
    padding: 2em;
}

.cb-quote-statistics-panel .cb-generate-document-button {
    display: block!important;
    width: 33%!important;
    min-width: 250px!important;
    margin: auto!important;
}