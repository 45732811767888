@import '../../../assets/styles/Colors.scss';

.ui.form.cb-change-password-form .field label {
    color: $dark-green;
}

.ui.form.cb-change-password-form .field .action.input .cb-show-password-button {
    background-color: $mid-dark-grey;
    color: transparent-black(0.8);
    border-radius: 0;
}

.ui.form.cb-change-password-form .field .action.input .cb-show-password-button:hover {
    background-color: $light-grey;
    color: black;
}