@import '../../../assets/styles/Colors.scss';

.cb-sidebar-title {
    display: block;
    padding: 0.8em 1.2em;
    margin-top: 1em;
    font-size: 1.1em;
    color: transparent-white(0.9);
    background-color: #193102;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
}

.cb-sidebar-title-content .icon {
    margin-right: 0.5em;
}