@import '../../../assets/styles/Colors.scss';

.cb-overview-wrapper {
    max-width: 100%;
    border-radius: 4px;
    background-color: $light-green;
    border: 1px solid rgba(34, 36, 38, 0.15);
    margin-top: 3em;
}

.cb-overview-search-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.3em 3.75em;
    border-bottom: 1px solid rgba(34, 36, 38, 0.15);
}

.cb-overview-search-bar .cb-search-input {
    width: 50%;
}

.cb-overview-search-bar .cb-search-input input {
    border: 2px solid #2A4365!important;
}


@media only screen and (max-width: 767px) {
    .cb-overview-search-bar .cb-search-input {
        width: 100%;
    }
}

.cb-overview-grid {
    padding: 1.5em 3em;
    max-height: 68vh;
    overflow-y: auto;
    margin: 0!important;
}

.cb-overview-wrapper .cb-empty-message {
    width: 60%!important;
    margin: 2em auto!important;
}