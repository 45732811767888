.cb-accounts-management-wrapper .cb-accounts-management-shortcuts {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 4em;
}

.cb-accounts-management-wrapper .cb-accounts-management-shortcuts .cb-shortcut-card {
    width: 70%;
    min-height: 11vw;
}