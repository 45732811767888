@import '../../../assets/styles/Colors.scss';


@media only screen and (min-width: 768px) {
    .cb-quote-request-form-segment {
        padding: 3em!important;
    }
}

.cb-quote-request-form .cb-form-header {
    margin-top: 0!important;
    padding: 0.3em 0 0.3em 0.5em!important;
    background-color: #FBFBFB;
    border: 1px solid rgba(34, 36, 38, 0.15)!important;
}

.cb-quote-request-actions {
    margin-top: 3em;
    padding-top: 2em;
    text-align: right;
    border-top: 2px solid rgba(34, 36, 38, 0.15);
}

.cb-quote-request-actions .ui.button:not(:last-child) {
    margin: 0 0.5em 0 0!important;
}

.cb-quote-request-actions .ui.button:last-child {
    margin: 0!important;
}

.cb-cancel-quote-request-form {
    background-color: rgba(181, 181, 181, 0.6)!important;
    color: #111!important;
    box-shadow: 0 0 0 1px #AAA inset!important;
}

.cb-cancel-quote-request-form:hover {
    background-color: rgba(181, 181, 181, 0.4)!important;
}

.cb-save-quote-request-form,
.cb-continue-quote-request-form {
    box-shadow: 0 0 0 1px #27292A inset!important;
    color: #27292A!important;
}

.cb-save-quote-request-form:hover,
.cb-continue-quote-request-form:hover {
    background: transparent-white(0.5)!important;
}

.cb-quote-request-form .cb-top-margin-big {
    margin-top: 3em!important;
}

.cb-quote-request-form .cb-top-margin-medium {
    margin-top: 2em!important;
}

.cb-quote-request-form .cb-top-margin-small {
    margin-top: 1.4em!important;
}

.cb-quote-request-form .cb-half-width {
    width: 50%!important;
}

@media only screen and (max-width: 767px) {
    .cb-quote-request-form .cb-half-width {
        width: 100%!important;
    }
}